import './styles/index.scss';
import Typed from 'typed.js';
import { initializeBurgerReact } from './burgerInit';
import { initializeAccessButtonReact, initializeMainButtonReact, } from './mainButtonInit';
import markerSDK from '@marker.io/browser';
import { initializeEnvTitleReact } from './envLineInit';
import { scrollSnapInitHomePage } from './scrollSnapInit';
import process from 'process';
import { authCookies } from '@ludo.ninja/api';
import { sliderInit } from './sliderInit';
import { ludoDomains } from "@ludo.ninja/core/build/ludoDomains";
window.addEventListener('DOMContentLoaded', function () {
    var url;
    var _a = authCookies.getCookies(), userId = _a.userId, wallets = _a.wallets, newUser = _a.newUser, inviteCode = _a.inviteCode, authToken = _a.authToken, refreshToken = _a.refreshToken, role = _a.role;
    if (authToken &&
        refreshToken &&
        userId &&
        wallets &&
        newUser &&
        inviteCode &&
        role) {
        url = "".concat(ludoDomains[process.env.ENV_VALUE]['profile'], "/").concat(userId, "/nfts");
    }
    else {
        url = "".concat(ludoDomains[process.env.ENV_VALUE]['auth'], "/login");
    }
    scrollSnapInitHomePage();
    sliderInit();
    var typed = new Typed('#typed', {
        strings: [
            'Collectibles',
            'Sports',
            'Events',
            'Artists',
            'Games',
            'PFPs',
            'dApps',
        ],
        typeSpeed: 50,
        backSpeed: 20,
        loop: true,
    });
    initializeAccessButtonReact({ url: url });
    initializeBurgerReact();
    initializeMainButtonReact({ url: url });
    initializeEnvTitleReact();
    markerSDK.loadWidget({ project: '65f2e310e9298c9dca4d4ca9' });
});
